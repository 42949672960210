import React from 'react';
import './CSS/Checkout.css';
//bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';
import { url,headers } from '../Constants'
import Navbar from './Navbar'
import StripeCheckout from 'react-stripe-checkout';
import PaypalBtn from 'react-paypal-checkout';
import {Icon, Divider,Avatar,Button,Modal,Input,Tooltip,message} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import states from './States.json'
class Checkout extends React.Component {
 constructor(props){
   super(props)
   this.initialState = {
    buyer:"",
    seller:"",
    listingTitle:"",
    description:"",
    totalCost:0,
    listing:"",
    address1:"",
    address2:"",
    fName:"",
    lName:"",
    country:"",
    state:"",
    zipCode:"",
    states:[],
    loading:false
   }
   this.state = {
     ...this.initialState
   }
   this.handleChange=this.handleChange.bind(this)
   this.hanldeSubmit=this.hanldeSubmit.bind(this)
 }
  async componentDidMount(){
    let checkoutData = await localStorage.getItem('checkoutData')
    if(checkoutData!==null){
      let data = JSON.parse(checkoutData)
      this.setState({
        totalCost:data.totalCost,
        buyer:data.buyer,
        seller:data.buyer,
        listingTitle:data.listingTitle,
        description:data.description,
        listing:data.listing
      })
    }else{
      this.props.history.push('/')
    }
  }

  handleChange(e){
    this.setState({
      [e.target.name]:e.target.value
    })
  }		
  hanldeSubmit(e){
    e.preventDefault()
    let {fName,lName,zipCode,country,state,buyer,seller,listing,totalCost,address1,address2} = this.state
    if(fName.length>0 && lName.length>0 && zipCode.length>0 && country.length>0 && state.length>0
      && buyer.length>0 && seller.length>0 && listing.length>0 && totalCost>0 && address1.length>0){
        let data = {
          buyer,
          seller,
          listing,
          totalCost,
          shippingAddress:{
            fName,
            lName,
            addressLine1:address1,
            addressLine2:address2,
            state,
            country,
            zipCode
          }
        }
        this.setState({
          loading:true
        })
        fetch(url+'/api/createNewOrder',{
          method:"POST",
          body:JSON.stringify(data),
          headers
        }).then(res=>res.json()).then(response=>{
          if(response.message==='Success'){
            // console.log('order createdddd-->',response)
            this.setState({
              ...this.initialState
            })
            message.success('Order created succesfully. You can see your purchases in your profile.')
            this.props.history.push('/')
          }else{
            console.log('error->',response)
            this.setState({
              loading:false
            })
            message.error('Could not create order '+response.err)
          }
        })
    }
    else{
      message.error('All details are required for order')
    }
  }
  render() {
    const onSuccess = (payment) => {
        // Congratulation, it came here means everything's fine!
        if(payment.paid===true){
                let body={
                    sellerDetails:this.props.item.userData._id,
                    buyerDetails:this.state.userData!==null?this.state.userData._id:"",
                    shippingProfile:this.props.item.doc.shippingID._id,
                    listingDetail:this.props.item.doc._id,
                    shippingAddress:payment.address,
                    firebaseUID:this.state.userData!==null?this.state.userData.firebaseUID:"",
                    sellerfirebaseID:this.props.item.userData.firebaseUID,
                    amount:this.state.final
                }
                fetch(url+'/api/createOrder',{body:JSON.stringify(body),method:"POST",headers:headers})
                .then(res=>res.json())
                .then(response=>{
                    if(response.message==='Success'){
                        this.props.history.push('/')
                        message.success("Congratulations! Your order has been succesfully created")
                    }
                })

        }
    }
    const onCancel = (data) => {
        // User pressed "cancel" or close Paypal's popup!
        console.log('The payment was cancelled!', data);
    }	

    const onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        console.log("Error!", err);		
    }			

    let env = 'sandbox'; // you can set here to 'production' for production
    let currency = 'USD'; // or you can set this value from your props or state  
    let total = 4.95;  // same as above, this is the total amount (based on currency) to be 
    let locale = 'en_US'; 
    // For Customize Style: https://developer.paypal.com/docs/checkout/how-to/customize-button/
    let style = {
        'label':'pay', 
        'tagline': false, 
        'size':'medium', 
        'shape':'pill', 
        'color':'gold'
    };

    const client = {
        sandbox:    'Ac3fi9IJihHJK__iAls2ZfFq7t7X327iR_KSBf75479hK9lcHHYuDsIDAStk7kin8_nFFkSpKmRW5qYn',
        production: 'AVsPdvCNZ5I0OB13_4fe2zE1feaqD_lL9b2jZbmhwuCCOyiL72ZhJfgp0WsNap_6ZcRaUr10pXRA_FLx',
        }
    return (
    
      <div className="maincontainer">
                <Navbar history={this.props.history} />
        <div className="container">
          <div className="py-5 text-center">
            <a href='#' style={{fontSize:20}}><ArrowLeftOutlined /> Go Back</a>
            
            <h2>Checkout form</h2>
          </div>

          <div className="row">
            <div className="col-md-4 order-md-2 mb-4">
              <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Your cart</span>
                <span className="badge badge-secondary badge-pill">1</span>
              </h4>
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h3 className="my-0">{this.state.listingTitle}</h3>
                    <small className="text-muted">{this.state.description}</small>
                  </div>
                  <span className="text-muted">$ {this.state.totalCost}</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total (USD)</span>
                  <strong>${this.state.totalCost}</strong>
                </li>
              </ul>

            </div>
            <div className="col-md-8 order-md-1">
              <h4 className="mb-3">Billing address</h4>
              <form className="needs-validation" novalidate>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label for="firstName">First name</label>
                    <input name='fName' onChange={this.handleChange} value={this.state.fName} type="text" className="form-control" id="firstName" placeholder="First Name" required />
                    <div className="invalid-feedback">
                      Valid first name is required.
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label for="lastName">Last name</label>
                    <input name='lName' onChange={this.handleChange} value={this.state.lName} type="text" className="form-control" id="lastName" placeholder="" required />
                    <div className="invalid-feedback">
                      Valid last name is required.
                    </div>
                  </div>
                </div>



                <div className="mb-3">
                  <label for="address">Address</label>
                  <input name='address1' onChange={this.handleChange}  value={this.state.address1} type="text" className="form-control" id="address" placeholder="1234 Main St" required />
                  <div className="invalid-feedback">
                    Please enter your shipping address.
                  </div>
                </div>

                <div className="mb-3">
                  <label for="address2">Address 2 <span className="text-muted">(Optional)</span></label>
                  <input name='address2' onChange={this.handleChange} value={this.state.address2} type="text" className="form-control" id="address2" placeholder="Apartment or suite" />
                </div>

                <div className="row">
                  <div className="col-md-5 mb-3">
                    <label for="country">Country</label>
                    <select value={this.state.country} onChange={e=>{
                      // console.log('sleccc-->',e.target.value)
                      let selectedddd = states.filter(state=>state.name===e.target.value)
                      this.setState({
                        country:e.target.value,
                        states:selectedddd[0].states
                      })
                    }} className="custom-select d-block w-100" id="country" required>
                      <option value="">Choose...</option>
                      {/* <option>United States</option> */}
                      {states.map((state)=><option value={state.name}>
                        {state.name}
                      </option>)}
                    </select>
                    <div className="invalid-feedback">
                      Please select a valid country.
                    </div>
                  </div>
                  {this.state.country!=="" && <div className="col-md-4 mb-3">
                    <label for="state">State</label>
                    <select onChange={e=>{
                      this.setState({
                        state:e.target.value
                      })
                    }} className="custom-select d-block w-100" id="state" required>
                      <option value="">Choose...</option>
                      {/* <option>California</option> */}
                      {this.state.states.map(st=><option value={st.name}>{st.name}</option>)}
                    </select>
                    <div className="invalid-feedback">
                      Please provide a valid state.
                    </div>
                  </div>}
                  <div className="col-md-3 mb-3">
                    <label for="zip">Zip</label>
                    <input name='zipCode' value={this.state.zipCode} onChange={this.handleChange} type="text" className="form-control" id="zip" placeholder="" required />
                    <div className="invalid-feedback">
                      Zip code required.
                    </div>
                  </div>
                </div>
                <hr className="mb-4" />
                {/* <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="same-address" />
                  <label className="custom-control-label" for="same-address">Shipping address is the same as my billing address</label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="save-info" />
                  <label className="custom-control-label" for="save-info">Save this information for next time</label>
                </div> */}
              <h2><b>*For demo purpose, payment methods have been disabled.</b></h2>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label for="cc-name">Name on card</label>
                    <input type="text" className="form-control" id="cc-name" placeholder="" required />
                    <small className="text-muted">Full name as displayed on card</small>
                    <div className="invalid-feedback">
                      Name on card is required
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label for="cc-number">Credit card number</label>
                    <input type="text" className="form-control" id="cc-number" placeholder="" required />
                    <div className="invalid-feedback">
                      Credit card number is required
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label for="cc-expiration">Expiration</label>
                    <input type="text" className="form-control" id="cc-expiration" placeholder="" required />
                    <div className="invalid-feedback">
                      Expiration date required
                    </div>
                  </div>
                  <div className="col-md-3 mb-3">
                    <label for="cc-expiration">CVV</label>
                    <input type="text" className="form-control" id="cc-cvv" placeholder="" required />
                    <div className="invalid-feedback">
                      Security code required
                    </div>
                  </div>
                </div>
                {this.state.listing!=='' && <div style={{display:"flex",justifyContent:"center",marginTop:20}}>
                <Button type='primary' onClick={this.hanldeSubmit} disabled={this.state.loading} shape='round' style={{backgroundColor:"darkgreen",borderColor:"darkgreen"}}>Finish Checkout</Button>
                </div>}
                <hr className="mb-4" />
                {/* <button className="btn btn-primary btn-lg btn-block" type="button">Buy Now</button>
                 */}
                                         <div style={{width:"100%",display:'flex',flexDirection:"row",alignItems:"center",marginTop:'50px',marginBottom:'20px'}}>
                            <StripeCheckout
                                style={{width:250,borderRadius:30,marginRight:40}}
                                amount={"495"}
                                description="Pay now for the listing"
                                image="https://d33wubrfki0l68.cloudfront.net/ca0061c3c33c88b2b124e64ad341e15e2a17af49/c8765/images/alligator-logo3.svg"
                                locale="auto"
                                name="Buy item"
                                label='Pay with Stripe'
                                stripeKey="pk_live_CcUK62qR7iD5ObbtumrdKI1U000PBiKFcJ"
                                token={this.onToken}
                                zipCode
                                shippingAddress
                            />


                            <PaypalBtn 
                                env={env} 
                                client={client} 
                                currency={currency} 
                                total={500} 
                                locale={locale} 
                                style={style}
                                onError={onError} 
                                onSuccess={onSuccess} 
                            />
                        </div>
              </form>
            </div>
          </div>

        </div>
     
      </div>
      
)
};
}

export default Checkout;