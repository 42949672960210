import React from "react";
import Navbar from "../Navbar";
import { Row, Col } from "antd";
import "../CSS/auction.css";
import { url } from "./../../Constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AuctionDetails from "./auctionDetails";
import BiddingDetail from './BiddingDetail'
import LiveVideo from "./liveVideo";
import LiveChat from "./liveChat";
import useWindowDimensions from "./dimentions";
import { SocketIOProvider } from "use-socketio";
import QuickMessages from "./quickMessages";

function AuctionLive(props) {
  console.log(props)
  let auctionID = props.match.params.auctionID;
  const { height, width } = useWindowDimensions();
  console.log(auctionID)
  return (
    <div>
      <Navbar />
      <Row style={{padding:10}}>
          <Col lg={7} xl={8} xxl={8}>
              <Row>
                <Col>
                  <AuctionDetails {...props} />
                </Col>
              </Row>
          </Col>
          <Col lg={11} xl={11} xxl={11}>
            <Row>
              <Col>
                <LiveVideo auctionID={auctionID} />
              </Col>
            </Row>
            <Row>
              <Col>
                <BiddingDetail {...props} />
              </Col>
            </Row>
          </Col>
          <Col lg={6} xl={5} xxl={5}>
              <Row>
                <Col>
                    <LiveChat auctionID={auctionID} />
                </Col>
              </Row>
              <Row>
                <Col>
                    <QuickMessages auctionID={auctionID} />
                </Col>
              </Row>
          </Col>
      </Row>
      {/* <Row style={{ margin: 10 }}>
        <Col
          xs={width > 800 ? 8 : 24}
          sm={width > 800 ? 8 : 24}
          md={width > 800 ? 8 : 24}
          lg={width > 800 ? 8 : 24}
          xl={width > 800 ? 8 : 24}
        >
          {width <= 800 && (
            <>
              <LiveVideo auctionID={auctionID} />
              <QuickMessages auctionID={auctionID} />
            </>
          )}
          {/* <AuctionDetails {...props} /> */}
          {/* {width <= 800 && (
            <>
              <LiveChat auctionID={auctionID} />
            </>
          )} */}
        {/* </Col>  */}
        {/* {width > 800 && (
            <Row>
              <Col xxl={16}>
                  <Row>
                    <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                      <LiveVideo auctionID={auctionID} />
                    </Col>
                  </Row>
                  <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <AuctionDetails {...props} />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <QuickMessages auctionID={auctionID} />
                      </Col>
                  </Row>
                  
              </Col>
              <Col xxl={2}>
                <LiveChat auctionID={auctionID} />
              </Col>
            </Row> */}
          {/* // <Col xs={16} sm={16} md={16} lg={16} xl={16}>
          //   <LiveVideo auctionID={auctionID} />
          //   <Row>
          //     <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          //       <QuickMessages auctionID={auctionID} />
          //     </Col>
          //     <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          //     <LiveChat auctionID={auctionID} />
          //     </Col>
          //   </Row>
          // </Col>
        )} */}

        {/* {width > 800 && (
          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
            <LiveChat auctionID={auctionID} />f
          </Col>
        )} */}
      {/* </Row> */}
    </div>
  );
}

export default function AuctionConfig(props) {
  let auctionID = props.match.params.auctionID;
  return (
    <SocketIOProvider
      url={url}
      opts={{
        query: {
          auctionID
        }
      }}
    >
      <AuctionLive {...props} />
    </SocketIOProvider>
  );
}
