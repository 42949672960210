import React, { Component } from 'react'
import { Button, Collapse } from 'antd'
import Navbar from './Navbar'
import { CaretRightOutlined } from '@ant-design/icons';

import './CSS/ShippingProfile.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimes, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import ProfileSidebar from './ProfileSidebar'
import { url, headers } from "../Constants";
import { connect } from 'react-redux';
// import { Button as Btn } from '@zendeskgarden/react-buttons';
// import Truncate from 'react-truncate';

// import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
const { Panel } = Collapse;
class Purchase extends Component {
    constructor(props) {
        super(props)
        this.initialState = {
            purchases: [],

            loading: false,
            show: true,
            showDetail: false
        }
        this.state = {
            ...this.initialState
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleOpenModal = () => {
        this.setState({ isAddShippment: true })
    }
    handleCloseModal = () => {
        this.setState({ isAddShippment: false })
    }
    handleCloseUpdateModal = () => {
        this.setState({ showUpdateModal: false })
    }
    handleChangeShipping = (e) => {
        this.setState({ shippingCheckBoxValue: e.target.value })
    }
    componentDidMount() {
        let userData = localStorage.getItem('userData')
        if (userData !== null) {
            let user = JSON.parse(userData)
            let UID = user.firebaseUID
            if (UID !== "") {
                let data = {
                    buyer: user._id
                }
                fetch(url + '/api/myPurchases', {
                    method: "POST",
                    headers,
                    body: JSON.stringify(data)
                })
                    .then(res => res.json())
                    .then(response => {
                        if (response.message === 'Success') {
                            console.log('purchases--->', response.doc)
                            this.setState({
                                purchases: response.doc
                            })

                        }
                        else {
                            alert("Failed to fetch shipping profiles")
                        }
                    })
            }
        }
        else {
            this.props.history.push('/')
        }
    }

    render() {
        return (
            <div className="Shipping-container">
                <Navbar />
                <div className="Shipping-body">
                    <ProfileSidebar />
                    <div className="Shipping-body-lists">
                        {/* {this.state.showDetail === false && <h2 style={{ textAlign: "center" }}> My Orders</h2>}
                        {this.state.showDetail === false &&
                            <List
                                itemLayout="horizontal"
                                pagination={{
                                    onChange: (page) => this.setState({ pageNumber: page }),
                                    pageSize: 2,
                                }}
                                dataSource={this.state.listData}
                                renderItem={(item) => (
                                    <List.Item>

                                        <List.Item.Meta
                                            avatar={
                                                <img style={{ width: 100, height: 100 }} alt='no item img' src={item.listingDetail.imageLinks[0]} />
                                            }
                                            title={<h2>{item.listingDetail.title}</h2>}
                                            description={<div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Truncate lines="2">{item.listingDetail.description}</Truncate>
                                                <Btn className='itemBtn' style={{ alignSelf: "flex-end" }}>View Order</Btn>
                                            </div>}
                                        />
                                    </List.Item>
                                )}
                            />}
                        <Modal footer={null} visible={this.state.showDetail} onCancel={() => { this.setState({ showBuyModal: false }) }}>
                            <br />
                            <div style={{ display: 'flex', justifyContent: "center", flexDirection: "row" }}>
                                <img src='https://images.pexels.com/photos/1571442/pexels-photo-1571442.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940' alt='no cover'
                                    style={{ height: 200, width: '70%' }}
                                />
                            </div>
                            <h2 style={{ textAlign: "center" }}>ITEM NAME</h2>

                            <span><h3>Price:</h3></span><span>$1000</span>
                            <br />
                        </Modal> */}

                        {/*
                        listing category
                        view listing
                        seller name
                        total cost
                        shipping address 1
                        shipping address 2
                        state
                        zip code
                        */}
                        <h2 style={{ textAlign: "center" }}> My Purchases</h2>
                        {this.state.purchases.length===0 && <h2 style={{textAlign:"center",marginTop:'20%'}}>No Purchases to show</h2>}
                        {this.state.purchases.length > 0 && <Collapse
                            bordered={false}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            className="site-collapse-custom-collapse"
                        >
                            {this.state.purchases.length > 0 && this.state.purchases.map((purchase, index) => {
                                let orderDate = new Date(purchase.createdDate)
                                let dateString = '' + orderDate.getMonth() + '/' + orderDate.getDate() + '/' + orderDate.getFullYear()
                                return <Panel header={purchase.listing.title + '  (Order Date: ' + dateString + ' )'} key={index.toString()} className="site-collapse-custom-panel">
                                    <p><b>Seller Name: </b> {purchase.seller.fName}</p>
                                    <p><b>Total cost: </b> {purchase.totalCost}</p>
                                    <br />
                                    <h3>Shipping Details</h3>
                                    <p><b>Shipping Address (Line 1): </b> {purchase.shippingAddress.addressLine1}</p>
                                    <p><b>Shipping Address (Line 2): </b> {purchase.shippingAddress.addressLine2}</p>
                                    <p><b>State: </b> {purchase.shippingAddress.state}</p>
                                    <p><b>Zip Code: </b> {purchase.shippingAddress.zipCode}</p>
                                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
                                        <Button type='primary' onClick={() => {
                                            // console.log('listttt-->',this.props.item)
                                            this.props.history.push('/itemdescription/' + purchase.listing.listingID)
                                        }} shape='round' style={{ backgroundColor: "darkgreen", borderColor: "darkgreen" }}>View Listing</Button>
                                    </div>
                                </Panel>
                            })}
                        </Collapse>}
                    </div>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return ({
        categories: state.rootReducer.categories,
        UID: state.rootReducer.UID,
        query: state.rootReducer.query,
        data: state.rootReducer.data
    })
}
function mapActionsToProps(dispatch) {
    return ({

    })
}
export default connect(mapStateToProps, mapActionsToProps)(Purchase)