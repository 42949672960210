import React, { Component } from 'react'
import Slide from 'react-reveal/Slide';
import Navbar from './Navbar'
import './CSS/Sponsorship.css'
import { Button,Divider,Modal,Input, Card} from 'antd'
import img1 from './images/SPS1.jpg'
import {Line} from 'react-chartjs-2'
import {Link} from 'react-router-dom'

import Fade from 'react-reveal/Fade';


class Spnsorship extends Component {

    state={
        
    }

    render() {


        

        const lineData = {
            labels: [
                'Day 1','Day 2','Day 3','Day 4','Day 5','Day 6','Day 7','Day 8','Day 9','Day 10',
                'Day 11','Day 12','Day 13','Day 14','Day 15','Day 16','Day 17','Day 18','Day 19','Day 20',
                'Day 21','Day 22','Day 23','Day 24','Day 25','Day 26','Day 27','Day 28','Day 29','Day 30',
            ],
            datasets: [
              {
                fill: false,
                lineTension: 0.1,
                borderColor: 'rgba(75,192,192,1)',
                pointBorderColor: 'red',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 10,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'red',
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                data: [230.560,788,2010,4067,8079,3000,3874,9098]
              }
            ]
          };


          var chartOptions = {
            showScale: true,
            pointDot: true,
            showLines: true,
            legend:{
                    display:false
            },

            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true,
                        min: 0,
                        max: 20000,
                        callback: function(label) {
                            return '$'+ label;}
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Amount'
                    }
                    }]
             }
        }

        return (
            <div className="sponsor-ship-container">
                <Navbar />
                <div className="sponsor-ship-body">
                 <Fade duration={1000}>
                 <div className="sponsor-ship-body-head-sec">
                        <Link to="sponsorship-detail/kDlEMW2KFIROMM" className="sponsor-ship-body-head-sec-box1 parent">
                            <div className="sponsor-ship-body-head-sec-box1-content child" >
                                <div className="sponsor-ship-body-head-sec-box1-content-text">
                                    <h4 style={{color:'#eeeeee',margin:0,fontWeight:'bold'}}>WORLDWIDE</h4>
                                    <h1 style={{color:'white',margin:0,fontWeight:'bold'}}>Coronavirus Relief Fund</h1>
                                </div>
                                <button className="btn">DONATE</button>
                            </div>
                        </Link>
                        <div className="sponsor-ship-body-head-sec-box2">
                            <div className="sponsor-ship-body-head-sec-box2-row1">
                                <div onClick={()=>this.props.history.push('/sponsorship-detail/kDlEMW2KFIROCC')} className="sponsor-ship-body-head-sec-box2-row1-col1">
                                    <div className="sponsor-ship-body-head-sec-box2-row1-col1-content child" >
                                        <div className="sponsor-ship-body-head-sec-box2-row1-col1-content-text">
                                            <h5 style={{color:'#eeeeee',margin:0,fontWeight:'bold'}}>WORLDWIDE</h5>
                                            <h2 style={{color:'white',margin:0,fontWeight:'bold'}}>Help Deprived Families of Afghanistan</h2>
                                        </div>
                                        <button className="btn">DONATE</button>
                                    </div>
                                </div>

                                <div className="sponsor-ship-body-head-sec-box2-row1-col2">
                                    <div onClick={()=>this.props.history.push('/sponsorship-detail/kDlEMW2KFIROXZ')} className="sponsor-ship-body-head-sec-box2-row1-col2-content child" >
                                        <div className="sponsor-ship-body-head-sec-box2-row1-col2-content-text">
                                            <h5 style={{color:'#eeeeee',margin:0,fontWeight:'bold'}}>WORLDWIDE</h5>
                                            <h2 style={{color:'white',margin:0,fontWeight:'bold'}}>Ethopian Children Fund</h2>
                                        </div>
                                        <button className="btn">DONATE</button>
                                    </div>
                                </div>
                            </div>
                            <div className="sponsor-ship-body-head-sec-box2-row2">
                                <div className="sponsor-ship-body-head-sec-box2-row2-col1">
                                    <div onClick={()=>this.props.history.push('/sponsorship-detail/kDlEMW2KFIROZV')} className="sponsor-ship-body-head-sec-box2-row2-col1-content child" >
                                        <div className="sponsor-ship-body-head-sec-box2-row2-col1-content-text">
                                            <h5 style={{color:'#eeeeee',margin:0,fontWeight:'bold'}}>WORLDWIDE</h5>
                                            <h2 style={{color:'white',margin:0,fontWeight:'bold'}}>Nigerian Children School</h2>
                                        </div>
                                        <button className="btn">DONATE</button>
                                    </div>
                                </div>
                                <div className="sponsor-ship-body-head-sec-box2-row2-col2">
                                    <div onClick={()=>this.props.history.push('/sponsorship-detail/kDlEMW2KFIRONN')} className="sponsor-ship-body-head-sec-box2-row2-col2-content child" >
                                        <div className="sponsor-ship-body-head-sec-box2-row2-col2-content-text">
                                            <h5 style={{color:'#eeeeee',margin:0,fontWeight:'bold'}}>WORLDWIDE</h5>
                                            <h2 style={{color:'white',margin:0,fontWeight:'bold'}}>Belarus Hospital Fund</h2>
                                        </div>
                                        <button className="btn">DONATE</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </Fade>
                    {/* Section 2 */}
                  <Slide left>
                  <div className="sponsor-ship-body-about-sec">
                        <div className="sponsor-ship-body-about-sec-content" >
                            <p style={{textAlign:'center',fontSize:22}}>We connects  <b>nonprofits</b>, <b>donors</b>, and <b>companies</b> in nearly 
                            every country around the world. We help local nonprofits access the funding, tools, training, and support they need to 
                            become more effective.</p>
                            <Button className="btn" size="large">Learn More</Button>
                        </div>
                    </div>
                  </Slide>

                    {/* Section 3 */}
                    <div className="sponsor-ship-body-number-sec">
                        <div className="sponsor-ship-body-number-sec-content">
                           <Slide down>
                           <div className="Col col1">
                                <h1 className="h1">18</h1>
                                <h4 className="h4">Years</h4>
                            </div>
                           </Slide>
                            <Slide down>
                            <div className="Col col3">
                                <h1 className="h1">1,745,343</h1>
                                <h4 className="h4">Donors</h4>
                            </div>
                            </Slide>
                        <Slide down>
                        <div className="Col col4">
                                <h1 className="h1">1,234</h1>
                                <h4 className="h4">Projects</h4>
                            </div>
                        </Slide>
                        <Slide down>
                        <div className="Col col5">
                                <h1 className="h1">176</h1>
                                <h4 className="h4">Countries</h4>
                            </div>
                        </Slide>
                        <Slide down>
                        <div className="Col col6">
                                <h1 className="h1">320</h1>
                                <h4 className="h4">Companies</h4>
                            </div>
                        </Slide>
                        </div> 
                    </div>

                    {/* Section 4 */}
                   <Fade duration={2000}>
                   {/* <div className="sponsor-ship-body-news-sec">
                        <h1>News</h1>
                        <div className="sponsor-ship-body-news-sec-content">
                            <div className="Col col1">
                                <Card
                                    className="Card"
                                    cover={<img src={img1} style={{height: 250}} />}
                                    >
                                   <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                        <h3 style={{margin:0,fontWeight:'bold',color:'#8b0000'}}>Coronavirus Relief</h3>
                                        <p style={{margin:0,textAlign:'center'}}>You can donate directly to a specific project below or to GlobalGiving’s Coronavirus Relief Fund and we'll allocate it to where it's needed most.</p>
                                    </div>
                                </Card>
                            </div>
                            <div className="Col col2">
                                <Card
                                    className="Card"
                                    cover={<img src={img1} style={{height: 250}} />}
                                    >
                                  <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                        <h3 style={{margin:0,fontWeight:'bold',color:'#8b0000'}}>Coronavirus Relief</h3>
                                        <p style={{margin:0,textAlign:'center'}}>You can donate directly to a specific project below or to GlobalGiving’s Coronavirus Relief Fund and we'll allocate it to where it's needed most.</p>
                                    </div>
                                </Card>
                            </div>
                            <div className="Col col3">
                                <Card
                                    className="Card"
                                    cover={<img src={img1} style={{height: 250}} />}
                                    >
                                    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                        <h3 style={{margin:0,fontWeight:'bold',color:'#8b0000'}}>Coronavirus Relief</h3>
                                        <p style={{margin:0,textAlign:'center'}}>You can donate directly to a specific project below or to GlobalGiving’s Coronavirus Relief Fund and we'll allocate it to where it's needed most.</p>
                                    </div>
                                    
                                </Card>
                            </div>
                        </div> 
                    </div> */}
                   </Fade>

                    {/* Section 4 */}
                    <div className="sponsor-ship-body-graph-sec">
                        <h2 style={{color:'#8b0000'}}>Last 30 Days Graph</h2>
                        <Line data={lineData} options={chartOptions} width="100%" height="40%" />
                    </div>

                </div>
            </div>
        )
    }
}






export default Spnsorship;

