import React, { Component } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Carousel } from 'react-responsive-carousel'
import img1 from './images/SPS1.jpg'
import img2 from './images/SPS2.jpg'
import img3 from './images/SPS3.jpg'
import img4 from './images/SPS4.jpg'
import './CSS/SponsorshipDetail.css'
import { Button, Divider, Modal, Input, Card, Progress, Icon } from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'


const width = window.innerWidth;
class Spnsorship extends Component {
    constructor(props){
        super(props)
        this.state = {
            windowWidth: width,
            donation: 'once',
            menu: 'story',
            title: "Coronavirus Relief Fund",
            raised: 0,
            total: 0,
            donations: 0,
            otherTitles:[]
        }
        this.separator = this.separator.bind(this)
    }
    separator(numb) {
        var str = numb.toString().split(".");
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return str.join(".");
    }
    componentDidMount() {
        window.addEventListener('resize', () => {
            this.setState({ windowWidth: window.innerWidth });
        })
        // console.log('link-->',window.location.href)
        const slashes = window.location.href.split('/')
        // console.log('slashes-->',slashes)
        const ids = ["kDlEMW2KFIROMM","kDlEMW2KFIROCC","kDlEMW2KFIROXZ","kDlEMW2KFIROZV","kDlEMW2KFIRONN"]
        const indexOfId = ids.indexOf(slashes[4])
        const data = [
            {
                title: "Coronavirus Relief Fund",
                raised: 120000000,
                total: 200000000,
                donations: 23010,
                index:0
            },
            {
                title: "Help Deprived Families of Afghanistan",
                raised: 274800000,
                total: 500000000,
                donations: 10399,
                index:1
            },
            {
                title: "Ethopian Children Fund",
                raised: 170000074,
                total: 300000000,
                donations: 18097,
                index:2
            },
            {
                title: "Nigerian Children School",
                raised: 84700003,
                total: 450000000,
                donations: 4854,
                index:3
            },
            {
                title: "Belarus Hospital Fund",
                raised: 66005600,
                total: 321000000,
                donations: 23219,
                index:4
            }
        ]
        const titles = data.filter((dat)=>dat.index!==indexOfId).map(values=>values.title)
        // console.log('titlesss->',titles)
        this.setState({
            ...data[indexOfId],
            otherTitles:titles
        })
    }

    render() {
        const images = [
            { image: img1 },
            { image: img2 },
            { image: img3 },
            { image: img4 }
        ]
        return (
            <div className="sponsor-ship-detail-container">
                <Navbar />
                <div className="sponsor-ship-detail-body">
                    <h1 style={{ fontSize: 40, fontWeight: 100, color: '#8b0000' }}>{this.state.title}</h1>
                    <div className="sponsor-ship-detail-body-main-sec">

                        <div className="sponsor-ship-detail-body-main-sec-discrip-sec">
                            <Carousel
                                autoPlay
                                showThumbs={false}
                                showStatus={false}
                                showIndicators={false}
                                showArrows={false}
                                width={(this.state.windowWidth <= 375) ? 340 : (this.state.windowWidth <= 576) ? 400 : (this.state.windowWidth <= 768) ? 680 : (this.state.windowWidth <= 1024) ? 620 : (this.state.windowWidth <= 1280) ? 700 : 800}
                                infiniteLoop
                            >
                                {
                                    images.map(item => {
                                        return <img src={item.image} style={this.state.windowWidth <= 375 ? { height: '220px' } : this.state.windowWidth <= 576 ? { height: '260px' } : this.state.windowWidth <= 1024 ? { height: '400px' } : { height: '500px' }} />
                                    })
                                }
                            </Carousel>
                            <div className="menues">
                                <Button
                                    type="primary"
                                    size="default"
                                    className={this.state.menu === 'story' ? "btn-1" : "btn"}
                                    onClick={() => this.setState({ menu: 'story' })}
                                >Story</Button>
                                <Button
                                    type="primary"
                                    size="default"
                                    className={this.state.menu === 'update' ? "btn-1" : "btn"}
                                    onClick={() => this.setState({ menu: 'update' })}
                                >Update</Button>
                                <Button
                                    type="primary"
                                    size="default"
                                    className={this.state.menu === 'faq' ? "btn-1" : "btn"}
                                    onClick={() => this.setState({ menu: 'faq' })}
                                >FAQ</Button>
                            </div>
                            <div className="content">
                                {
                                    this.state.menu === 'story' ?
                                        <Story />
                                        : this.state.menu === 'update' ?
                                            <Update />
                                            :
                                            <FAQ />
                                }
                            </div>
                        </div>
                        <div className="sponsor-ship-detail-body-main-sec-donation-sec">
                            <Card className="Card" style={{ height: 250 }}>
                                <div className="Card-container">
                                    <div className="card-head">
                                        <h2 style={{ margin: 0, fontWeight: 'bold' }}>${this.separator(this.state.raised)}</h2>
                                        <p style={{ margin: 0, fontSize: 16, marginLeft: 5 }}>raised of ${this.separator(this.state.total)}</p>
                                    </div>
                                    <div className="card-body">
                                        <p style={{ margin: 0, fontWeight: 'bold', lineHeight: 0 }}>Goal</p>
                                        <Progress strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }} percent={(this.state.raised/this.state.total)*100} showInfo={false} />
                                    </div>
                                    <div className="card-foot">
                                        <p style={{ fontSize: 16 }}><b>{this.separator(this.state.donations)}</b> donations</p>
                                        <p style={{ fontSize: 16 }}><b>${this.separator(this.state.total-this.state.raised)}</b> to go</p>
                                    </div>
                                </div>
                            </Card>
                            <Button size="large" style={{ width: '100%', marginTop: 50, backgroundColor: '#ee4053', color: 'white', fontWeight: 'bold' }} >DONATE NOW</Button>

                            <div className="Donation">
                                <div className="Donation-Head">
                                    <Button
                                        size="large"
                                        className={this.state.donation === 'once' ? "btn1" : "btn"}
                                        onClick={() => this.setState({ donation: 'once' })}
                                    >Donate Once</Button>
                                    <Button
                                        size="large"
                                        className={this.state.donation === 'monthly' ? "btn1" : "btn"}
                                        onClick={() => this.setState({ donation: 'monthly' })}
                                    >Donate Monthly</Button>
                                </div>
                                <div className="Donation-Body">
                                    {
                                        this.state.donation === 'once' ?
                                            <div>

                                                <div className="donation-card">
                                                    <div className="content">
                                                        <div style={{ width: '20%', textAlign: 'center' }}>
                                                            <h3 style={{ margin: 0, fontWeight: 'bold' }}>$15</h3>
                                                            <p style={{ margin: 0 }}>US</p>
                                                        </div>
                                                        <div style={{ width: '80%', paddingLeft: 5 }}>
                                                            <p style={{ margin: 0 }}>Immediate relief and long term support</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon">{'>'}</div>
                                                </div>
                                                <div style={{ marginTop: 10 }} className="donation-card">
                                                    <div className="content">
                                                        <div style={{ width: '20%', textAlign: 'center' }}>
                                                            <h3 style={{ margin: 0, fontWeight: 'bold' }}>$25</h3>
                                                            <p style={{ margin: 0 }}>US</p>
                                                        </div>
                                                        <div style={{ width: '80%', paddingLeft: 5 }}>
                                                            <p style={{ margin: 0 }}>Immediate relief and long term support</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon">{'>'}</div>
                                                </div>
                                                <div style={{ marginTop: 10 }} className="donation-card">
                                                    <div className="content">
                                                        <div style={{ width: '20%', textAlign: 'center' }}>
                                                            <h3 style={{ margin: 0, fontWeight: 'bold' }}>$50</h3>
                                                            <p style={{ margin: 0 }}>US</p>
                                                        </div>
                                                        <div style={{ width: '80%', paddingLeft: 5 }}>
                                                            <p style={{ margin: 0 }}>Immediate relief and long term support</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon">{'>'}</div>
                                                </div>
                                                <div style={{ marginTop: 10 }} className="donation-card">
                                                    <div className="content">
                                                        <div style={{ width: '20%', textAlign: 'center' }}>
                                                            <h3 style={{ margin: 0, fontWeight: 'bold' }}>$100</h3>
                                                            <p style={{ margin: 0 }}>US</p>
                                                        </div>
                                                        <div style={{ width: '80%', paddingLeft: 5 }}>
                                                            <p style={{ margin: 0 }}>Immediate relief and long term support</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon">{'>'}</div>
                                                </div>
                                            </div>

                                            :
                                            <div>

                                                <div style={{ marginTop: 10 }} className="donation-card">
                                                    <div className="content">
                                                        <div style={{ width: '25%', textAlign: 'center' }}>
                                                            <h3 style={{ margin: 0, fontWeight: 'bold' }}>$15</h3>
                                                            <p style={{ margin: 0, lineHeight: 1 }}>US</p>
                                                            <p style={{ margin: 0, lineHeight: 1 }}>Monthly</p>
                                                        </div>
                                                        <div style={{ width: '75%', paddingLeft: 10 }}>
                                                            <p style={{ margin: 0 }}>Immediate relief and long term support</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon">{'>'}</div>
                                                </div>
                                                <div style={{ marginTop: 10 }} className="donation-card">
                                                    <div className="content">
                                                        <div style={{ width: '25%', textAlign: 'center' }}>
                                                            <h3 style={{ margin: 0, fontWeight: 'bold' }}>$25</h3>
                                                            <p style={{ margin: 0, lineHeight: 1 }}>US</p>
                                                            <p style={{ margin: 0, lineHeight: 1 }}>Monthly</p>
                                                        </div>
                                                        <div style={{ width: '75%', paddingLeft: 10 }}>
                                                            <p style={{ margin: 0 }}>Immediate relief and long term support</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon">{'>'}</div>
                                                </div>
                                                <div style={{ marginTop: 10 }} className="donation-card">
                                                    <div className="content">
                                                        <div style={{ width: '25%', textAlign: 'center' }}>
                                                            <h3 style={{ margin: 0, fontWeight: 'bold' }}>$50</h3>
                                                            <p style={{ margin: 0, lineHeight: 1 }}>US</p>
                                                            <p style={{ margin: 0, lineHeight: 1 }}>Monthly</p>
                                                        </div>
                                                        <div style={{ width: '75%', paddingLeft: 10 }}>
                                                            <p style={{ margin: 0 }}>Immediate relief and long term support</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon">{'>'}</div>
                                                </div>
                                                <div style={{ marginTop: 10 }} className="donation-card">
                                                    <div className="content">
                                                        <div style={{ width: '25%', textAlign: 'center' }}>
                                                            <h3 style={{ margin: 0, fontWeight: 'bold' }}>$100</h3>
                                                            <p style={{ margin: 0, lineHeight: 1 }}>US</p>
                                                            <p style={{ margin: 0, lineHeight: 1 }}>Monthly</p>
                                                        </div>
                                                        <div style={{ width: '75%', paddingLeft: 10 }}>
                                                            <p style={{ margin: 0 }}>Immediate relief and long term support</p>
                                                        </div>
                                                    </div>
                                                    <div className="icon">{'>'}</div>
                                                </div>
                                            </div>

                                    }

                                </div>
                            </div>

                            <Button size="large" style={{ width: '100%', marginTop: 50, backgroundColor: '#8b0000', color: 'white', fontWeight: 'bold' }} >
                                <Icon type="heart" />
                                Add To Favorites
                            </Button>

                            <div className="social-buttons">
                                <Button type="primary" size="large" style={{ width: '50%', borderRadius: 0, backgroundColor: "#3dacf1" }}>
                                    <Icon type="twitter" />
                                    Twitter
                                </Button>
                                <Button type="primary" size="large" style={{ width: '50%', borderRadius: 0, backgroundColor: '#40599b' }}>
                                    <Icon type="facebook" />
                                    Facebook
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="related-projects-sec">
                        <h2>Related Projects</h2>
                        <div className="related-project-body">
                                {this.state.otherTitles.length>0 && this.state.otherTitles.map((title)=><div className="related-project-card" style={{ backgroundImage: `url(${img1})` }} >
                                <div className="card-content">
                                    <h5 style={{ color: '#eeeeee', margin: 0, fontWeight: 'bold' }}>WORLDWIDE</h5>
                                    <h2 style={{ color: 'white', margin: 0, fontWeight: 'bold' }}>{title}</h2>
                                    <Button className="btn" size="large">DONATE</Button>
                                </div>
                            </div>)}
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}


const Story = () => {
    return (
        <div style={{ width: '100%' }}>
            <h1>Story</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>Story</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>Story</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>Story</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

        </div>
    )
}

const Update = () => {
    return (
        <div style={{ width: '100%' }}>
            <h1>Update</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>Update</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>Update</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>Update</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

        </div>
    )
}

const FAQ = () => {
    return (
        <div style={{ width: '100%' }}>
            <h1>FAQ</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>FAQ</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>FAQ</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

            <h1>FAQ</h1>
            <p>The COVID-19 pandemic marks an unprecedented time in modern history that will
                require the best of humanity to overcome. Your donation to this fund will help
                stop COVID-19's spread and protect us all.</p>

        </div>
    )
}




export default Spnsorship;

